import { useQuasar } from "quasar"

export function useNotification()
{
    const $q = useQuasar()

    function notificationTimeout(msg: string): number {
        return msg.length > 100 ? 10000 : 5000
    }

    function intentNotificate(page: Page)
    {
        if(page.props.flash.notification)
        {
            const value = page.props.flash.notification

            $q.notify({
                type: value.type,
                message: value.msg,
                html: value.html,
                timeout: notificationTimeout(value.msg)
            })

            //This is by prevent duplicate notifications
            page.props.flash.notification = null
        }
    }

    return {intentNotificate, notificationTimeout}
}